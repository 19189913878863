<div cdkDropList
    class="item-dropzone parent"
    [ngClass]="item.name == 'Document definition' ? 'document-definition-wrapper' : ''"
    [id]="parentItemId"
    [cdkDropListData]="parentItem"
    [cdkDropListConnectedTo]="allDropListsIds"
    (cdkDropListDropped)="onDragDrop($event)">
    <div cdkDrag
        [id]="item.uId"
        [cdkDragData]="item"
        [cdkDragDisabled]="dragDisabled"
        class="d-flex justify-content-between align-items-center"
        [ngClass]="item.itemType"
        (click)="onSelect()">
        <div class="d-flex align-items-center">
            <div title="Drag this item with children"
                class="item-drag-handle"
                cdkDragHandle>
                <i *ngIf="!dragDisabled"
                class="material-icons">
                drag_handle
                </i>
            </div>
            <i *ngIf="item.itemType === 'string'"
                class="material-icons ml-auto">
                format_quote
            </i>
            <i *ngIf="item.itemType === 'number'"
                class="material-icons ml-auto">
                tag
            </i>
            <i *ngIf="item.itemType === 'object'"
                class="material-icons ml-auto">
                data_object
            </i>
            <i *ngIf="item.itemType === 'array'"
                class="material-icons ml-auto">
                data_array
            </i>
            <i *ngIf="item.itemType === 'boolean'"
                class="material-icons ml-auto">
                toggle_off
            </i>
            <i *ngIf="item.itemType === 'definition'"
                class="material-icons ml-auto">
                inventory_2
            </i>
            <!-- TODO: Keep from dragging item when editing name -->
            <input [(ngModel)]="item.name"
                *ngIf="!dragDisabled && item.itemType != 'definition'"
                class="input-name" 
                [size]="item.name.length - 1 || 1"
                [ngClass]="item.name.length == 0 ? 'input-name-warning' : ''">
            <select class="ml-2" [(ngModel)]="item.name" *ngIf="!dragDisabled && item.itemType == 'definition'">
                <option *ngFor="let definition of definitions.children" 
                    [ngValue]="definition.name">
                    <!-- TODO: keep from selecting string/number if this item has children -->
                {{definition.name}}
                </option>
                <option [ngValue]="'$url'">
                    🔗 url
                </option>
            </select>
            <span *ngIf="dragDisabled" class="ml-1 mr-3">
                {{ item.name }}
            </span>
            <i *ngIf="!selected && !dragDisabled"
                class="material-icons icon-big ml-auto">
                arrow_right
            </i>
            <i *ngIf="selected && !dragDisabled"
                class="material-icons icon-big ml-auto">
                arrow_drop_down
            </i>
            <button class="btn-add" (click)="onAddItem(item)" *ngIf="item.addChildren && (item.itemType == 'object' || item.itemType == 'array')">
                <i class="material-icons">
                add_box
                </i>
            </button>
        </div>
        <div class="d-flex align-items-center">
            <!-- <label class="ml-5 mr-1 mb-0 align-middle">Type:</label> -->
            <select class="mr-3" [(ngModel)]="item.itemType" *ngIf="!dragDisabled">
                <option *ngFor="let type of types" [ngValue]="type.value"
                    [disabled]="(type.value != 'array' && item.itemType == 'array' && item.children[0].children.length > 0) 
                    || (type.value != 'object' && item.itemType == 'object' && item.children.length > 0)">
                    <!-- TODO: keep from selecting string/number if this item has children -->
                {{type.viewValue}}
                </option>
            </select>
            <!-- TODO: Add 'change order' buttons -->
            <button class="btn-add" (click)="onDuplicateItem([parentItem, item])"
                *ngIf="!dragDisabled">
                <i class="material-icons">
                content_copy
                </i>
            </button>
            <div class="btn-order-wrapper d-flex align-items-center" >
                <button *ngIf="!dragDisabled && currentIndex > 0"
                    class="btn-order d-flex align-items-center"
                    (click)="onMoveItemUp([parentItem, item])">
                    <i class="material-icons">
                    keyboard_arrow_up
                    </i>
                </button>
            </div>
            <div class="btn-order-wrapper d-flex align-items-center mr-3" >
                <button *ngIf="!dragDisabled && currentIndex < parentItem?.children?.length - 1"
                    class="btn-order d-flex align-items-center"
                    (click)="onMoveItemDown([parentItem, item])">
                    <i class="material-icons">
                    keyboard_arrow_down
                    </i>
                </button>
            </div>
            <button class="mr-2 btn-delete" (click)="onRemoveItem([parentItem, item])"
                *ngIf="!dragDisabled">
                <i class="material-icons mt-1">
                clear
                </i>
            </button>
        </div>
        <div class="item-drag-preview"
            *cdkDragPreview>
            {{item.name}} with {{item.children.length}} child item(s)
        </div>
    </div>
    <div *ngIf="selected"
        class="item-properties">
        <label class="col-3">
            Description:
        </label>
        <input [(ngModel)]="item.description"
            class="input-properties col-9" 
            size="auto"
            [ngClass]="item.url?.length == 0 ? 'input-name-warning' : ''">
        <div *ngIf="item.itemType == 'definition' && item.name == '$url'">
            <label class="col-3">
                URL:
            </label> 
            <input [(ngModel)]="item.url"
                class="input-properties col-9" 
                size="auto"
                [ngClass]="item.url?.length == 0 ? 'input-name-warning' : ''">
        </div>
        
    </div>
    <ul cdkDropList *ngIf="item.itemType == 'object' || item.itemType == 'array'"
        class="item-dropzone"
        [id]="item.uId"
        [cdkDropListConnectedTo]="connectedDropListsIds"
        [cdkDropListData]="item"
        (cdkDropListDropped)="onDragDrop($event)"
        [cdkDropListEnterPredicate]="canDrop(item.itemType)">
        <li *ngFor="let subItem of item.children"
            [ngClass]="subItem.itemType + '-ul'">
            <list-item [item]="subItem"
                [parentItem]="item"
                [connectedDropListsIds]="allDropListsIds"
                [definitions]="definitions"
                (itemDrop)="onDragDrop($event)"
                (addItem)="onAddItem($event)"
                (duplicateItem)="onDuplicateItem($event)"
                (removeItem)="onRemoveItem($event)"
                (moveItemUp)="onMoveItemUp($event)"
                (moveItemDown)="onMoveItemDown($event)">
            </list-item>
        </li>
    </ul>
</div>
