<nav class="navbar navbar-expand-md navbar-dark fixed-top">
    <a class="navbar-brand" href="https://creatoratnight.com">
        <img src="https://creatoratnight.com/site_logo.gif" class="header-logo">
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarsExampleDefault">
      <ul class="navbar-nav mr-auto">
      </ul>
      <div class="form-inline my-2 my-lg-0">
        <div ngbDropdown class="d-inline-block">
            <button class="btn btn-outline-yellow" id="dropdownBasic1" ngbDropdownToggle>Experiments</button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="header-dropdown">
              <button ngbDropdownItem [routerLink]="['/001']">#001: The Game of Life</button>
              <button ngbDropdownItem [routerLink]="['/002']">#002: Perlin Noise Terrain Generator</button>
              <button ngbDropdownItem [routerLink]="['/003']">#003: Lorenz System Visualization</button>
              <button ngbDropdownItem [routerLink]="['/004']">#004: 3D Fractal Tree Generator</button>
              <button ngbDropdownItem [routerLink]="['/005']">#005: Drag & Drop JSON Schema Editor</button>
            </div>
          </div>
      </div>
    </div>
  </nav>